<template>
<!-- Header For Large Screen-->
<div id="head" class=" header-web flex header-web justify-between w-full h-24 bg-black  shadow-sm" data-aos="flip-down" >
  <div class="w-60 h-10  lg:ml-40 ml-7 cursor-pointer">
    <router-link to="/"><img :src="images.logo" alt="logo" original></router-link>
  </div>
  <nav class="md:ml-auto flex top flex-wrap items-center text-base justify-center">
      <a href="#products" class="mr-5 text-xl top text-black hover:text-blueNew1">Nos cartes </a>
      <a href="#howitwork" class="mr-5 text-xl top   text-black  hover:text-blueNew1">Comment ça marche?</a>
      <router-link to="/actu"> <a href="#tarif" class="mr-5 text-xl  top text-black  hover:text-blueNew1">Actualité</a></router-link>
      <router-link to="/faq"> <a href="#tarif" class="mr-5 text-xl  top text-black  hover:text-blueNew1">FAQ</a></router-link>
    </nav>
  <div class="mt-6 mb-4 inline-flex lg:mr-40 mr-7">
    <button class="create-button p-6 h-12 rounded-full text-white text-lg flex justify-center items-center cursor-pointer bg-blueNew1"><a class="text-white" href="https://app.tagango.com/">Connexion</a>
    </button>
  </div>
</div>
<div class="header-mobile h-20 bg-black">
    <div class="header-container pl-4 pr-4 flex items-center">
      <div class="flex-one w-1/2">
        <router-link to="/">
        <img :src="images.logo" alt="logo" class="" original> 
        </router-link>
      </div>
      <div class="flex-one w-1/2  h-full  burger-section items-center flex justify-end mr-4">
        <div class="burger flex flex-col " v-if="!showMobileMenu" @click="showMobileMenu = !showMobileMenu">
          <div class="line m-1"></div>
          <div class="line m-1"></div>
          <div class="line m-1"></div>
        </div>
        <div class="close-burger flex flex-col" v-if="showMobileMenu" @click="showMobileMenu = !showMobileMenu">
          <div class="line "></div>
          <div class="line "></div>
        </div>
      </div>
    </div>
    <div class="shadow absolute w-full mobile-menu-items mr-12 bg-white modal-body" :class="{ 'mobile-menu-items-active': showMobileMenu}">
      <slot name="body">
      <div class="pl-4 pr-4 pt-4 w-full">
        <nav class="md:ml-auto flex tops flex-wrap items-center text-base justify-center">
          <ul>
            <li><a href="#products" class=" flex-one mr-5 text-xl tops text-black hover:text-blueNew1">Nos cartes </a></li>
            <li><a href="#howitwork" class="flex-one mr-5 text-xl tops   text-black  hover:text-blueNew1">Comment ça marche?</a></li>
            <router-link to="/faq"> <a href="#tarif" class="mr-5 text-xl  tops text-black  hover:text-blueNew1">FAQ</a></router-link>
          </ul>
          
       </nav>
     <!--   <div class="flex-one text-right w-full h-16 flex items-center ">
          <router-link to="/contact" class="ml-6 mr-6 text-black text-semibold no-underline"> {{ $t('signIn1') }} </router-link>
           </div> -->
           <div class="mt-6 mb-4 inline-flex lg:mr-40 mr-7">
    <button class="create-button p-6 h-12 rounded-full text-white text-lg flex justify-center items-center cursor-pointer bg-blueNew1"><a class="text-white" href="https://app-tagang.surge.sh/">Connexion</a>
    </button>
  </div>
      </div>
    </slot>
    </div>
  </div>
</template>

<script>

/*Import Images*/
import logo from '../../assets/images/logos.webp'

export default {
 
  name: "index",
  data () {
    return {
      icons: {
      },
      images:{
        logo
      },
      showMobileMenu: false,
      profileMenu:null,
    }
  },
  methods: {
    openForm() {
      document.getElementById("myForm").style.display = "block";
    },
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/styles/sass/variables.scss";
a {
  color: white;
}
.header-mobile  {
  display: none;
  background-color: black !important;
}

.burger {
  cursor: pointer;
  .line {
    height: .1rem;
    width: 2rem;
    background-color: #81c141;
  }
}
.close-burger  {
  cursor: pointer;
  .line {
    height: .2rem;
    width: 2rem;
    background-color: #81c141;
  }
  .line:first-child {
    transform: rotate(-45deg);
  }
  .line:last-child {
    transform: rotate(45deg);
  }
}

.mobile-menu-items {
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.26s ease;
  z-index: 9999;
}
.mobile-menu-items-active {
  transform: scaleY(1);
}

@media only screen and (max-width: 600px) {
  .header-mobile {
    display: block;
    z-index: 9999;
    background-color: black;
  }
  .tops{
    color: black;
  }
  .header-web {
    display: none;
  }
}

</style>



